<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Kullanıcı Yetki İşlemleri'"
    :total="rows.length"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
  >
    <template v-slot:leftBody>
      <b-alert variant="warning" show class="text-center m-3" v-if="!rows.length">
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>
      <ul class="list-group" v-else>
        <YetkiItem v-for="(item, index) in rows" :key="index" :item="item" @handlerUpdate="handlerUpdate($event)" />
      </ul>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <YetkiEkle v-else :updateData="updateData" @show="show = $event" @handlerClose="type = $event" />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { ref, watch, defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
import YetkiEkle from './component/YetkiEkle.vue';
import YetkiItem from './component/YetkiItem.vue';
export default defineComponent({
  components: {
    BListCard,
    YetkiEkle,
    YetkiItem,
  },
  setup() {
    const expo = {};
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.form = ref({
      role_adi: 'Yeni Yetki',
      authority: [
        {
          title: 'Mağaza İslemleri',
          modul: 'magaza-yonetim',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Ürün İşlemleri',
          modul: 'urun-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Sipariş İşlemleri',
          modul: 'siparis-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Finans İşlemleri',
          modul: 'finans-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Üye İşlemleri',
          modul: 'uye-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Tanimlamalar',
          modul: 'tanimlamalar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Raporlar',
          modul: 'raporlar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Kullanicilar',
          modul: 'kullanicilar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Ayarlar',
          modul: 'ayarlar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
      ],
    });

    expo.rows = computed(() => store.getters.getRoller);

    store.dispatch('rolleListele');

    expo.handlerNewItem = () => {
      expo.show.value = true;
      store.dispatch('rolleEkle', expo.form.value).then((res) => {
        expo.handlerUpdate(res.data.data);
      });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    watch(expo.search, (val) => {});

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
