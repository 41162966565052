<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form
      @submit.stop.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
      class="p-2"
      autocomplete="off"
    >
      <b-row>
        <b-col cols="12">
          <validation-provider
            name="Ad Soyad"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group label="Ad Soyad">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="ad_soyad"
                v-model="form.ad_soyad"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider
            name="Kullanıcı Adı ( Email )"
            :rules="{ required: true, email: true }"
            v-slot="validationContext"
          >
            <b-form-group label="Kullanıcı Adı ( Email )">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="email"
                v-model="form.email"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider
            name="Şifre"
            :rules="userSifreControl"
            v-slot="validationContext"
          >
            <b-form-group label="Şifre">
              <b-input-group>
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="sifre"
                  v-model="form.sifre"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-input-group-prepend is-text>
                  <i
                    :class="`fad fa-${passwordToggleIcon}`"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Telefon" label-for="telefon">
            <b-form-input
              size="lg"
              class="rounded-0"
              id="telefon"
              name="telefon"
              ref="telefon"
              v-model="form.telefon"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-provider
            name="Role"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group
              label="Role Seçiniz"
              label-for="role_adi"
              :class="{ 'is-invalid': !!validationContext.errors[0] }"
            >
              <v-select
                v-model="form.role_k_no"
                :options="roller"
                :reduce="(role) => role.k_no"
                label="role_adi"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
                :class="{ 'is-invalid': !!validationContext.errors[0] }"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Profil Resmi" label-for="role_adi">
            <b-form-file
              size="lg"
              placeholder="Bir resim seçiniz"
              drop-placeholder="Drop file here..."
              no-drop
              v-model="profile"
              accept="image/jpeg, image/png, image/jpg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu">Kullanım Durumu</label>
            <b-form-checkbox
              id="statu"
              :checked="form.statu"
              v-model="form.statu"
              switch
              size="lg"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button
              squared
              block
              type="submit"
              size="lg"
              variant="primary"
              class="mr-2"
            >
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{
                form.k_no == null ? "EKLE" : "GÜNCELLE"
              }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex justify-content-end">
            <b-button
              squared
              size="lg"
              variant="secondary"
              @click="$emit('handlerClose', false)"
            >
              <i class="fad fa-times-square"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import {
  defineComponent,
  ref,
  computed,
  toRefs,
  watch,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
    roller: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();

    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.profile = ref(null);
    expo.roller = ref([]);
    expo.master = ref("user");
    expo.passwordFieldType = ref("password");
    expo.form = ref({
      k_no: null,
      ad_soyad: null,
      e_mail: null,
      sifre: null,
      telefon: null,
      role_k_no: null,
      statu: true,
    });

    const { updateData } = toRefs(props);

    expo.passwordToggleIcon = computed(() => {
      return expo.passwordFieldType.value === "password" ? "eye-slash" : "eye";
    });

    expo.togglePasswordVisibility = () => {
      expo.passwordFieldType.value =
        expo.passwordFieldType.value === "password" ? "text" : "password";
    };

    expo.userSifreControl = computed(() => {
      if (expo.form.value.k_no != null) {
        return { required: false };
      } else {
        return { required: true, min: 4 };
      }
    });

    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        ad_soyad: null,
        email: null,
        sifre: null,
        telefon: null,
        role_k_no: null,
        statu: true,
      };
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      let formData = new FormData();
      let forms = expo.form.value;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });

      if (expo.profile.value !== null) {
        formData.append("profile", expo.profile.value, expo.profile.value.name);
      }

      const base_url =
        expo.form.value.k_no == null ? "kullaniciEkle" : "kullaniciGuncelle";
      store.dispatch(base_url, formData).then((res) => {
        if (res.data.success === true) {
          toast.success(
            expo.form.value.k_no == null
              ? "Ekleme Başarılı."
              : "Güncelleme Başarılı",
            { position: "bottom-left" }
          );
          context.emit("show", false);
          if (expo.form.value.k_no == null) {
            expo.resetForm();
          }
        }
      });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
