<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Kullanıcı İşlemleri'"
    :total="rows.length"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
  >
    <template v-slot:leftBody>
      <b-alert
        variant="warning"
        show
        class="text-center m-3"
        v-if="!rows.length"
      >
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>
      <ul class="list-group" v-else>
        <KullaniciItem
          v-for="(item, index) in rows"
          :key="index"
          :item="item"
          @handlerUpdate="handlerUpdate($event)"
        />
      </ul>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <KullaniciEkle
          v-else
          :updateData="updateData"
          :roller="roller"
          @show="show = $event"
          @handlerClose="type = $event"
        />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from "@/components/cards/BListCard.vue";
import { ref, watch, defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import KullaniciEkle from "./component/KullaniciEkle.vue";
import KullaniciItem from "./component/KullaniciItem.vue";
export default defineComponent({
  components: {
    BListCard,
    KullaniciEkle,
    KullaniciItem,
  },
  setup() {
    const expo = {};
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.rows = computed(() => store.getters.getKullanicilar);
    expo.roller = computed(() => store.getters.getRoller);

    const handlerGetData = () => {
      expo.show.value = true;
      store.dispatch("rolleListele");
      store.dispatch("kullaniciListele");
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = () => {
      expo.updateData.value = {};
      expo.type.value = true;
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    watch(expo.search, (val) => {});

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
